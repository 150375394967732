exports.components = {
  "component---packages-blog-gatsby-blog-core-src-templates-collection-author-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.author.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-author-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-post-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/post.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-post-js" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-404-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/404.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-404-jsx" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-authors-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/authors.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-authors-jsx" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-contact-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/contact.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-contact-jsx" */),
  "component---src-pages-app-index-jsx": () => import("./../../../src/pages/app/index.jsx" /* webpackChunkName: "component---src-pages-app-index-jsx" */),
  "component---src-pages-avkastning-index-jsx": () => import("./../../../src/pages/avkastning/index.jsx" /* webpackChunkName: "component---src-pages-avkastning-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-om-agino-index-jsx": () => import("./../../../src/pages/om-agino/index.jsx" /* webpackChunkName: "component---src-pages-om-agino-index-jsx" */),
  "component---src-pages-oversikt-index-jsx": () => import("./../../../src/pages/oversikt/index.jsx" /* webpackChunkName: "component---src-pages-oversikt-index-jsx" */),
  "component---src-pages-pris-index-jsx": () => import("./../../../src/pages/pris/index.jsx" /* webpackChunkName: "component---src-pages-pris-index-jsx" */),
  "component---src-pages-samarbeid-index-jsx": () => import("./../../../src/pages/samarbeid/index.jsx" /* webpackChunkName: "component---src-pages-samarbeid-index-jsx" */),
  "component---src-pages-sensor-index-jsx": () => import("./../../../src/pages/sensor/index.jsx" /* webpackChunkName: "component---src-pages-sensor-index-jsx" */),
  "component---src-pages-varmesum-index-jsx": () => import("./../../../src/pages/varmesum/index.jsx" /* webpackChunkName: "component---src-pages-varmesum-index-jsx" */)
}

